import React, { useState } from 'react';
import { Button, FormControl, HStack, Input, Text, VStack } from '@chakra-ui/react'
import { Auth } from 'aws-amplify';

const ConfirmSignUp = ({ authState, onStateChange, onErrorNotify }) => {

  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');

  if (authState !== 'confirmSignUp') {
    return null;
  }

  return (
    <VStack>
      <Text fontSize={24}>Confirm sign up</Text>
      <FormControl>
        <Input
          type='text'
          placeholder='Username'
          onChange={e => setUsername(e.target.value)} />
      </FormControl>
      <FormControl>
        <Input
          type='text'
          placeholder='Code'
          onChange={e => setCode(e.target.value)} />
      </FormControl>
      <Button onClick={() => {
        Auth.confirmSignUp(username, code)
          .then(() => onStateChange('signIn'))
          .catch(e => onErrorNotify(e.message));
      }}>Confirm</Button>
      <HStack>
        <Button
          onClick={() => {
            onStateChange('signIn');
          }}
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            textDecoration: 'underline',
            color: '#069',
            cursor: 'pointer'
          }}>Back to sign in</Button>
        <Button
          onClick={() => {
            Auth.resendSignUp(username)
              .then(() => { })
              .catch(e => onErrorNotify(e.message));
          }}
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            textDecoration: 'underline',
            color: '#069',
            cursor: 'pointer'
          }}>Resend code</Button>
      </HStack>
    </VStack>
  );
};

export default ConfirmSignUp;