import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import CurrentLocationInfoWidget from '../components/CurrentLocationInfoWidget';
import SpeedWidget from '../components/SpeedWidget';
import TripMapWrapper from '../components/TripMapWrapper';
import queries from '../graphql-queries';


const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Widget = () => {

  const query = useQueryParams();
  const params = {};
  query.forEach((value, key) => {
    if (!isNaN(value)) {
      params[key] = parseInt(value);
    } else {
      params[key] = value;
    }
  });

  const { data } = useQuery(queries.GET_LATEST_TRIP, {
    variables: { username: params.username },
    fetchPolicy: 'network-only'
  });

  if (!params.tripId) {
    params.tripId = data?.getUser?.trips?.items[0]?.tripId;
  }

  if (params.type === 'map') {
    return <TripMapWrapper {...params} />
  }

  if (params.type === 'location' && params.tripId) {
    return <CurrentLocationInfoWidget {...params} />
  }

  if (params.type === 'speed' && params.tripId) {
    return <SpeedWidget {...params} />
  }

  return <></>;
};

export default Widget;