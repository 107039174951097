import React from 'react';
import { Box } from '@chakra-ui/react'
import { Route, Switch } from 'react-router-dom';
import Navigator from './Navigator';
import routes from '../routes.js'
import Footer from './Footer';
import Sidebar from './Sidebar';
import Widget from '../pages/Widget';

const Main = () => {

  return (
    <Switch>
      <Route exact path='/widget'>
        <Widget />
      </Route>
      <Box minH='100vh'>
        <Sidebar routes={routes} w='60' />
        <Navigator ml='60' />
        <Box ml='60' p='4'>
          {routes.map((prop, key) => {
            return (
              <Route exact path={prop.path} key={key}>
                <prop.component />
              </Route>
            );
          })}
        </Box>
      </Box>
      <Footer ml='60' />
    </Switch>
  );
};

export default Main;