import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: 'auth',
  initialState: {
    username: "",
    userAttributes: {}
  },
  reducers: {
    signIn: (state, action) => {
      state.username = action.payload.username;
      state.userAttributes = action.payload.userAttributes;
    },
    signOut: state => {
      state.username = "";
      state.userAttributes = {};
    }
  }
});

export const { signIn, signOut } = slice.actions;
export const selectUserId = state => state.auth.userAttributes.sub;
export const selectUsername = state => state.auth.username;
export const selectUserAttributes = state => state.auth.userAttributes;

export default slice.reducer;