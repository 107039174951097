import 'dotenv/config';

const config = {

  // aws
  region: process.env.REACT_APP_AWS_REGION,

  // appsync
  url: process.env.REACT_APP_APPSYNC_URL,
  apiKey: process.env.REACT_APP_APPSYNC_API_KEY,

  // cognito
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  authenticationFlowType: process.env.REACT_APP_COGNITO_AUTH_TYPE,

  // google
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};

export default config;