import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import queries from '../graphql-queries';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Heading, Image, Link, Select, Switch, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react'
import TripMapWrapper from './TripMapWrapper';

const TripMetadata = ({ username, tripId, ...rest }) => {

  const { loading, error, data } = useQuery(queries.GET_TRIP_METADATA, {
    variables: { username, tripId }
  });

  if (loading) {
    return <Image src='/images/loading.gif' alt='Loading trip info' />;
  }

  if (error) {
    console.log('Error fetching trip info', error);
    return <Box>Error fetching trip info</Box>;
  }

  if (!data.getTrip) {
    return <Box>No trip metadata</Box>;
  }

  return (
    <Box {...rest}>
      <Heading fontSize={22}>
        Trip details
      </Heading>
      <Box mt='3'>
        <Table>
          <Thead>
            <Tr>
              <Th>Attribute</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Id</Td>
              <td>{data.getTrip.tripId}</td>
            </Tr>
            <Tr>
              <Td>Start Time</Td>
              <Td>{new Date(data.getTrip.startTime * 1000).toLocaleString()}</Td>
            </Tr>
            <Tr>
              <Td>End Time</Td>
              <Td>{data.getTrip.endTime && new Date(data.getTrip.endTime * 1000).toLocaleString()}</Td>
            </Tr>
            <Tr>
              <Td>Status</Td>
              <Td>{data.getTrip.status}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

const TripPage = () => {

  const { username, tripId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [widgetLink, setWidgetLink] = useState('');
  const [widgetType, setWidgetType] = useState('');
  const [isProcessedEnabled, setIsProcessedEnabled] = useState(false);
  const [refreshTime, setRefreshTime] = useState(Date.now());
  const toggleSwitch = () => {
    setIsProcessedEnabled(prev => !prev);
    setRefreshTime(Date.now());
  }

  const onWidgetClose = () => {
    setWidgetType('');
    setWidgetLink('');
    onClose();
  };

  return (
    <VStack m='2' align='flex-start'>
      <Link
        color='teal.500'
        as={RouterLink}
        to={`/member/${username}`}>
        <Text as='u'>See all trips from {username.toLowerCase()}</Text>
      </Link>
      <Box>
        <Button onClick={onOpen}>Widget</Button>
      </Box>
      <TripMetadata username={username} tripId={tripId} pt={3} />
      <>
        <FormControl display='flex' alignItems='center'>
          <FormLabel>
            <Text>Show smooth route</Text>
          </FormLabel>
          <Switch id='smooth-route' isChecked={isProcessedEnabled} onChange={toggleSwitch} />
        </FormControl>
        <TripMapWrapper username={username} tripId={tripId} isProcessedEnabled={isProcessedEnabled} refreshTime={refreshTime} pt={3} />
      </>
      <Drawer isOpen={isOpen} placement='right' onClose={onWidgetClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create widget</DrawerHeader>
          <DrawerBody>
            <VStack>
              <Select placeholder='Select widget option' onChange={e => setWidgetType(e.target.value)}>
                <option value='map'>Map</option>
                <option value='location'>Location</option>
                <option value='speed'>Speed</option>
              </Select>
              <Box alignSelf='end'>
                <Link href={widgetLink}>{widgetLink}</Link>
              </Box>
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={onWidgetClose} mx={1}>Cancel</Button>
            <Button onClick={e => {
              setWidgetLink(window.location.href.replace(window.location.hash, `#/widget?type=${widgetType}&username=${username}&tripId=${tripId}`));
            }}>Get Widget</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </VStack>
  );
};

export default TripPage;