import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import queries from '../graphql-queries';
import { Box, Button, Heading, Link, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom';

const Trip = ({ trip, onDelete, index }) => {

  return (
    <Tr>
      <Td>
        <Link color='teal.500' as={RouterLink} to={loc => `${loc.pathname}/${trip.tripId}`}>
          <Text as='u'>{index}</Text>
        </Link>
      </Td>
      <Td>{new Date(trip.startTime * 1000).toLocaleString()}</Td>
      {trip.endTime ? <Td>{new Date(trip.endTime * 1000).toLocaleString()}</Td> : <Td></Td>}
      <Td>{trip.status}</Td>
      {trip.status !== 'IN_PROGRESS' &&
        // TODO: if trip being delete is selected, we should remove the associated trip details
        <Td><Button onClick={() => {
          if (window.confirm(`Are you sure you want to delete trip ${trip.tripId} started on ${new Date(parseInt(trip.startTime)).toString()}?`)) {
            onDelete();
          }
        }}>Delete</Button></Td>
      }
    </Tr>
  );
};

const TripTable = ({ username }) => {

  const [deleteTrip] = useMutation(queries.DELETE_TRIP);

  const { loading, error, data, refetch } = useQuery(queries.GET_USER, {
    variables: { username },
    pollInterval: 30_000
  });

  if (loading) {
    return <Box>Loading trips...</Box>;
  }

  if (error) {
    console.log('Error fetching trips', error);
    return <Box>Error fetching trips</Box>;
  }

  const trips = data.getUser.trips.items;

  return (
    <Box>
      <Heading fontSize={22}>Trips</Heading>
      <Box mt='3'>
        <Table>
          <Thead>
            <Tr>
              <Th>Trip</Th>
              <Th>Start Time</Th>
              <Th>End Time</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {trips.map(t => <Trip
              key={t.tripId}
              trip={t}
              index={trips.findIndex(x => x.tripId === t.tripId) + 1}
              onDelete={() => {
                deleteTrip({ variables: { tripId: t.tripId } });
                refetch();
              }}
            />)}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default TripTable;