import { useSubscription } from '@apollo/client';
import { Box, Text } from '@chakra-ui/react';
import { LoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import config from '../config-exports';
import queries from '../graphql-queries';

const Wrapper = (props) => {

  const [geocoder, setGeocoder] = useState(null);

  return (
    <LoadScript googleMapsApiKey={config.googleMapsApiKey} onLoad={() => {
      setGeocoder(new window.google.maps.Geocoder())
    }}>
      <CurrentLocationInfoWidget geocoder={geocoder} {...props} />
    </LoadScript >);
};

const CurrentLocationInfoWidget = ({ username, tripId, geocoder, ...rest }) => {

  const [positionInfo, setPositionInfo] = useState({});
  const [latestFetchTime, setLatestFetchTime] = useState(0);

  const { data } = useSubscription(queries.TRIP_SUBSRIPTION, {
    variables: {
      username: username,
      tripId: tripId
    }
  });

  useEffect(() => {
    if (data?.onLocationDatum && Date.now() - latestFetchTime > 60_000 && geocoder) {
      setLatestFetchTime(Date.now());
      geocoder
        .geocode({
          location: {
            lat: data.onLocationDatum.latitude,
            lng: data.onLocationDatum.longitude,
          }
        })
        .then(res => {
          setPositionInfo({
            admin_area_level_2: res.results.filter(r => r.types.includes('administrative_area_level_2'))[0]?.formatted_address
          })
        })
    }
  }, [data, latestFetchTime, geocoder]);

  return positionInfo?.admin_area_level_2 ?
    <Box
      bg='black'
      color='white'
      p={3}
      display='flex'
      alignItems='center'
      justifyContent='center'
      w={200}
      h={25}
      {...rest}
    >
      <Text>{positionInfo.admin_area_level_2}</Text>
    </Box >
    : <></>;
};

export default Wrapper;