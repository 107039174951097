import React, { useState } from 'react';
import { Button, FormControl, HStack, Input, Text, VStack } from '@chakra-ui/react'
import { Auth } from 'aws-amplify';

const SignIn = ({ authState, onStateChange, onErrorNotify }) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  if (!['signIn', 'signedOut', 'signedUp'].includes(authState)) {
    return null;
  }

  return (
    <VStack>
      <Text fontSize={24}>Sign in to your account</Text>
      <FormControl>
        <Input
          type='text'
          placeholder='Username'
          onChange={e => setUsername(e.target.value)} />
      </FormControl>
      <FormControl>
        <Input
          type='password'
          placeholder='Password'
          onChange={e => setPassword(e.target.value)} />
      </FormControl>
      <Button onClick={() => {
        Auth.signIn(username, password)
          .then(u => onStateChange('signedIn', u))
          .catch(e => onErrorNotify(e.message));
      }}>Sign In</Button>
      <HStack>
        <Button
          onClick={() => onStateChange('signUp')}
          disabled={true}
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            textDecoration: 'underline',
            color: '#069',
            cursor: 'pointer'
          }}>Register</Button>
        <br />
        <Button onClick={() => onStateChange('confirmSignUp')} style={{
          background: 'none',
          border: 'none',
          padding: 0,
          textDecoration: 'underline',
          color: '#069',
          cursor: 'pointer'
        }}>Confirm a registered account</Button>
      </HStack>
    </VStack>
  );
};

export default SignIn;