import { gql } from '@apollo/client';

const queries = {

  GET_USER: gql`
    query user($username: String!, $tripLimit: Int, $tripNextToken: String) {
      getUser(username: $username, tripLimit: $tripLimit, tripNextToken: $tripNextToken) {
        username
        createTime
        trips {
          items {
            username
            tripId
            startTime
            endTime
            status
          }
          nextToken
        }
        following {
          username
        }
      }
    }`,

  GET_LATEST_TRIP: gql`
    query latestTrip($username: String!) {
      getUser(username: $username, tripLimit: 1) {
        trips {
          items {
            tripId
          }
        }
      }
    }`,

  GET_TRIP_METADATA: gql`
    query trip($username: String! $tripId: ID!) {
      getTrip(username: $username tripId: $tripId) {
        username
        tripId
        status
        startTime
        endTime
        privacy
        description
      }
    }`,

  GET_TRIP_POSITIONS: gql`
    query tripPositions($username: String! $tripId: ID!) {
      getTrip(username: $username tripId: $tripId) {
        username
        tripId
        status
        compressedProcessedPositions
        latitudes
        longitudes
        accuracies
      }
    }`,

  TRIP_SUBSRIPTION: gql`
    subscription onLocationDatum($username: String! $tripId: ID!) {
      onLocationDatum(username: $username tripId: $tripId) {
        username
        tripId
        latitude
        longitude
        accuracy
        speed
        time
      }
    }`,

  DELETE_TRIP: gql`
    mutation deleteTrip($tripId: ID!) {
      deleteTrip(tripId: $tripId) {
        username
        tripId
      }
    }`,
};

export default queries;