import { Box, Container, Text } from '@chakra-ui/react'

const Footer = ({ ...rest }) => {

  return (
    <Box
      bg='gray.50'
      borderTopWidth='1px'
      borderTopColor='gray.200'
      {...rest}>
      <Container
        py='4'
        justify='center'
        align='center'>
        <Text>© {new Date().getFullYear()} Zotobai</Text>
      </Container>
    </Box>
  );
};

export default Footer;