import React, { useState } from 'react';
import { Button, FormControl, HStack, Input, Text, VStack } from '@chakra-ui/react'
import { Auth } from 'aws-amplify';

const SignUp = ({ authState, onStateChange, onErrorNotify }) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  if (authState !== 'signUp') {
    return null;
  }

  return (
    <VStack>
      <Text fontSize={24}>Create a new account</Text>
      <FormControl>
        <Input
          type='text'
          placeholder='Username'
          onChange={e => setUsername(e.target.value)} />
      </FormControl>
      <FormControl>
        <Input
          type='email'
          placeholder='email@example.com'
          onChange={e => setEmail(e.target.value)} />
      </FormControl>
      <FormControl>
        <Input
          type='password'
          placeholder='Password'
          onChange={e => setPassword(e.target.value)} />
      </FormControl>
      <Button onClick={() => {
        Auth.signUp(username, password, email)
          .then(() => onStateChange('confirmSignUp'))
          .catch(e => onErrorNotify(e.message));
      }}>Create account</Button>
      <HStack>
        <Button
          onClick={() => {
            onStateChange('signIn');
          }}
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            textDecoration: 'underline',
            color: '#069',
            cursor: 'pointer'
          }}>Back to sign in</Button>
      </HStack>
    </VStack>
  );
};

export default SignUp;