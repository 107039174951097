import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Map from './Map';
import queries from '../graphql-queries';
import { Box, Image } from '@chakra-ui/react'
import { unzipSync } from 'react-zlib-js';

const TripMapWrapper = ({ username, tripId, isProcessedEnabled, refreshTime, ...rest }) => {

  const { subscribeToMore, loading, error, data } = useQuery(queries.GET_TRIP_POSITIONS, {
    variables: { username, tripId }
  });

  if (loading) {
    return <Image src='/images/loading.gif' alt='Loading trip info' fluid />;
  }

  if (error) {
    console.log('Error fetching trip info', error);
    return <Box>Error fetching trip info</Box>;
  }

  if (!data.getTrip) {
    return <Box>No trip map</Box>;
  }

  return (
    <Box {...rest}>
      <TripMap
        data={data}
        subscribeToMore={subscribeToMore}
        isProcessedEnabled={isProcessedEnabled}
        refreshTime={refreshTime}
      />
    </Box>)
};

const TripMap = ({ data, subscribeToMore, isProcessedEnabled, refreshTime }) => {

  useEffect(() => {

    if (data.getTrip.status === 'IN_PROGRESS') {
      console.log('Subscribing');
      const unsubscribe = subscribeToMore({
        document: queries.TRIP_SUBSRIPTION,
        variables: { username: data.getTrip.username, tripId: data.getTrip.tripId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          const newLocation = subscriptionData.data.onLocationDatum;
          console.log('New location: ', newLocation);
          return {
            getTrip: Object.assign({}, prev.getTrip, {
              latitudes: [...prev.getTrip.latitudes, newLocation.latitude],
              longitudes: [...prev.getTrip.longitudes, newLocation.longitude],
              accuracies: [...prev.getTrip.accuracies, newLocation.accuracy],
            })
          };
        }
      });

      return () => {
        console.log('Unsubscribing');
        unsubscribe();
      };
    }
  }, [data.getTrip.status, data.getTrip.username, data.getTrip.tripId, subscribeToMore]);

  let processedPositions = [];
  if (data.getTrip.compressedProcessedPositions) {
    processedPositions =
      JSON.parse(
        unzipSync(
          Buffer.from(data.getTrip.compressedProcessedPositions, 'base64'),
        ).toString(),
      ).map(p => {
        return {
          latitude: p[0],
          longitude: p[1],
        };
      });
  }

  const positions = [];

  for (let i = 0; i < Math.min(data.getTrip?.latitudes?.length, data.getTrip?.longitudes?.length, data.getTrip?.accuracies?.length); i++) {
    positions.push({
      latitude: data.getTrip.latitudes[i],
      longitude: data.getTrip.longitudes[i],
      accuracy: data.getTrip.accuracies[i],
    });
  }

  return <Map
    positions={isProcessedEnabled ? processedPositions : positions.filter(p => p.accuracy < 100)}
    refreshTime={refreshTime}
    zoom={data.getTrip.status === 'IN_PROGRESS' ? 'FOLLOW' : 'FULL'}
    isInProgress={data.getTrip.status === 'IN_PROGRESS'} />;
};

export default TripMapWrapper;