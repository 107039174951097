import React, { useEffect } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import config from './config-exports';
import Main from './components/Main';
import { useApolloClient } from './auth-link';
import store from './redux/store';
import { signIn, signOut } from './redux/authSlice';

import { HashRouter } from 'react-router-dom';

Amplify.configure({
  Auth: {
    region: config.region,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    authenticationFlowType: config.authenticationFlowType
  }
});

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    Auth.currentUserInfo()
      .then(response => {
        if (response) {
          dispatch(signIn({
            username: response.username,
            userAttributes: response.attributes
          }));
        }
      })
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    Hub.listen('auth', (data) => {
      if (isMounted) {
        if (data.payload.event === "signIn") {
          dispatch(signIn({
            username: data.payload.data.username,
            userAttributes: data.payload.data.attributes
          }));
        } else if (data.payload.event === "signOut") {
          dispatch(signOut());
        }
      }
    });
    return () => { isMounted = false };
  }, [dispatch]);

  return (
    <HashRouter>
      <Main />
    </HashRouter>
  );
};

const AppWithApollo = () => {

  const apolloClient = useApolloClient();

  return (
    <ReduxProvider store={store}>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </ReduxProvider>
  );
};

export default AppWithApollo;