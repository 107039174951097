import React from 'react';
import TripTable from '../components/TripTable';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserAttributes, selectUsername } from '../redux/authSlice';
import { Box, Divider, Heading, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'

// TODO: move to separate personal profile page
const ProfileInfo = () => {

  const userAttributes = useSelector(selectUserAttributes);
  return (
    <Box>
      <Heading>
        Profile Info
      </Heading>
      <Box>
        <Table>
          <Thead>
            <Tr>
              <Th>Attribute</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.keys(userAttributes).map(k => <Tr key={k}>
              <Td>{k}</Td>
              <Td>{userAttributes[k].toString()}</Td>
            </Tr>)}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

const Profile = () => {

  const history = useHistory();
  const { username } = useParams();
  const authUsername = useSelector(selectUsername);

  if (!username && authUsername) {
    history.push(`/member/${authUsername}`);
    return <>/</>;
  } else if (!username && !authUsername) {
    history.push('/login');
    return <>/</>;
  }

  return (
    <Box m='2'>
      <Heading align='center' size='xl'>{username.toUpperCase()}</Heading>
      <Divider mt='5' mb='10' />
      <TripTable username={username} />
    </Box>
  );
};

export default Profile;