import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

const Homepage = () => {
  return (
    <Box align='center' mt='3'>
      <Heading>Welcome to Zotobai</Heading>
    </Box>
  );
};

export default Homepage;