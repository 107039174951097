import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import TripDisplay from './components/TripDisplay';
import { AiOutlineHome } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Homepage,
    icon: AiOutlineHome,
    sidebar: true
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    icon: CgProfile,
    sidebar: true
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: '/member/:username/:tripId',
    name: 'Trip',
    component: TripDisplay
  },
  {
    path: '/member/:username',
    name: 'Member',
    component: Profile
  }
];

export default routes;