import { matchPath, useLocation } from 'react-router';
import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ routes, ...rest }) => {

  return (
    <Box
      borderRight='1px'
      borderRightColor='gray.200'
      pos='fixed'
      h='full'
      {...rest}>
      <Flex h='20' alignItems='center' mx='8' justifyContent='center'>
        <Text fontSize='2xl' fontFamily='monospace' fontWeight='bold'>
          Zotobai
        </Text>
      </Flex>
      {routes.filter(route => route.sidebar).map((route) => (
        <NavItem key={route.name} route={route} />
      ))}
    </Box>
  );
};

const NavItem = ({ route }) => {

  const location = useLocation();

  const isActiveRoute = (route) => {
    return matchPath(location.pathname, { path: route.path, exact: true, strict: false });
  }

  return (
    <Link
      to={route.path}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      as={NavLink}
      key={route.key}>
      <Box
        bg={isActiveRoute(route) ? 'lightgrey' : 'white'}
        m='1'
        borderRadius={4}>
        <Flex
          align='center'
          p='4'
          mx='4'
          borderRadius='lg'
          role='group'
          cursor='pointer'
          _hover={{
            bg: 'cyan.400',
            color: 'white',
          }}>
          {route.icon && (
            <Icon
              mr='4'
              fontSize='22'
              _groupHover={{
                color: 'white',
              }}
              as={route.icon}
            />
          )}
          <Text>{route.name}</Text>
        </Flex>
      </Box>
    </Link>
  );
};

export default Sidebar;