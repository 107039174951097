import { Authenticator } from 'aws-amplify-react';
import React, { useState } from 'react';
import SignIn from '../components/auth/SignIn';
import SignUp from '../components/auth/SignUp';
import ConfirmSignUp from '../components/auth/ConfirmSignup';
import { useSelector } from 'react-redux';
import { selectUserId } from '../redux/authSlice';
import { Alert, AlertIcon, Box, Text } from '@chakra-ui/react';

const Login = () => {

  const [errorMessage, setErrorMessage] = useState('');

  const userId = useSelector(selectUserId);

  const notify = (message) => {
    setErrorMessage(message);
  };

  if (userId) {
    return (
      <Box my='2' align='center'>
        <Text fontSize='xl'>You are logged in.</Text>
      </Box>
    );
  }

  return (
    <Box
      align='center'
      pt='2'>
      {errorMessage ?
        <Alert
          mb='5'
          borderRadius={4}
          status='error'
          onClick={() => setErrorMessage('')}>
          <AlertIcon />
          {errorMessage}
        </Alert>
        : <></>}
      <Authenticator hideDefault={true}>
        <SignIn onErrorNotify={notify} />
        <SignUp onErrorNotify={notify} />
        <ConfirmSignUp onErrorNotify={notify} />
      </Authenticator>
    </Box>
  );
};

export default Login;