import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import React, { memo, useCallback, useEffect, useState } from 'react';
import config from '../config-exports';

const Map = ({ positions, zoom, isInProgress, refreshTime }) => {

  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);

  useEffect(() => {

    if (map && zoom === 'FULL') {
      const bounds = new window.google.maps.LatLngBounds();

      const minLong = Math.min(...positions.map(p => p.longitude));
      const maxLong = Math.max(...positions.map(p => p.longitude));
      const minLat = Math.min(...positions.map(p => p.latitude));
      const maxLat = Math.max(...positions.map(p => p.latitude));

      bounds.extend({
        lat: maxLat,
        lng: maxLong,
      });
      bounds.extend({
        lat: maxLat,
        lng: minLong,
      });
      bounds.extend({
        lat: minLat,
        lng: maxLong,
      });
      bounds.extend({
        lat: minLat,
        lng: minLong,
      });
      map.fitBounds(bounds);
    }
    // We only want to set the boundaries at the beginning
    // not everytime there are new positions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, zoom, refreshTime]);

  return (
    positions[0]
      ? <LoadScript googleMapsApiKey={config.googleMapsApiKey}>
        <GoogleMap mapContainerStyle={{
          width: '800px',
          height: '600px'
        }}
          zoom={16}
          onLoad={onLoad}
          options={{
            disableDefaultUI: true
          }}
          center={{ lat: positions[positions.length - 1].latitude, lng: positions[positions.length - 1].longitude }}>
          <Polyline path={positions.map(p => { return { lat: p.latitude, lng: p.longitude } })} />
          <Marker
            position={{ lat: positions[0].latitude, lng: positions[0].longitude }}
            icon={{ url: '/images/map-start-marker.png', scaledSize: { width: 25, height: 45 } }} />
          <Marker
            position={{ lat: positions[positions.length - 1].latitude, lng: positions[positions.length - 1].longitude }}
            icon={isInProgress ? { url: '/images/motorcycle-icon.png', scaledSize: { width: 50, height: 50 } } : undefined} />
        </GoogleMap>
      </LoadScript >
      : <></>
  );
};

export default memo(Map);