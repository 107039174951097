import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/link-context';
import { onError } from "@apollo/link-error";
import Auth from "@aws-amplify/auth";
import { AUTH_TYPE, createAuthLink as awsCreateAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { Hub } from "@aws-amplify/core";
import { useEffect, useState } from "react";
import config from './config-exports';

let amplifyAuthLink = null;

const createAuthLink = () => cachedAmplifyAuthLink.concat(
  new ApolloLink((operation, forward) => operation.getContext().amplifyAuthLink.request(operation, forward)),
  resetToken
);

const createCognitoAuthLink = (session) => ApolloLink.from([
  awsCreateAuthLink({
    url: config.url,
    region: config.region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: session.getIdToken().getJwtToken()
    }
  }),
  createSubscriptionHandshakeLink({
    url: config.url,
    region: config.region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: session.getIdToken().getJwtToken()
    }
  })
]);

const createApiKeyAuthLink = () => ApolloLink.from([
  awsCreateAuthLink({
    url: config.url,
    region: config.region,
    auth: {
      type: AUTH_TYPE.API_KEY,
      apiKey: config.apiKey
    }
  }),
  createSubscriptionHandshakeLink({
    url: config.url,
    region: config.region,
    auth: {
      type: AUTH_TYPE.API_KEY,
      apiKey: config.apiKey
    }
  })
]);

const cachedAmplifyAuthLink = setContext(() => {
  if (amplifyAuthLink) {
    return { amplifyAuthLink };
  }
  return Auth.currentSession()
    .then(session => {
      amplifyAuthLink = createCognitoAuthLink(session);
      return { amplifyAuthLink };
    })
    .catch(error => {
      amplifyAuthLink = createApiKeyAuthLink();
      return { amplifyAuthLink };
    })
});

const resetToken = onError(({ networkError }) => {
  if (networkError?.name === "ServerError" && networkError?.statusCode === 401) {
    amplifyAuthLink = createApiKeyAuthLink();
  }
});

const addListeners = () => {
  const handleAuthEvents = ({ payload }) => {
    switch (payload.event) {
      case "signIn":
        amplifyAuthLink = createCognitoAuthLink(payload.data.signInUserSession);
        break;
      case "signOut":
        amplifyAuthLink = createApiKeyAuthLink();
        break;
      default:
        break;
    }
  }
  Hub.listen("auth", handleAuthEvents);
  return handleAuthEvents;
};

const removeListeners = (handler) => Hub.remove("auth", handler);

const createApolloClient = () =>
  new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Trip: {
          keyFields: ["tripId"]
        }
      }
    }),
    link: createAuthLink()
  })

export const useApolloClient = () => {

  const [client] = useState(() => createApolloClient());
  useEffect(() => {
    const handler = addListeners();
    return () => removeListeners(handler);
  });
  return client;
};