import React from 'react';
import { Auth } from 'aws-amplify';
import {
  Avatar,
  Flex,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Link,
  Box,
} from '@chakra-ui/react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { selectUsername } from '../redux/authSlice';
import { useSelector } from 'react-redux';

const Navigator = ({ ...rest }) => {

  const history = useHistory();
  const username = useSelector(selectUsername);

  return (
    <Flex
      px='4'
      height='20'
      alignItems='center'
      borderBottomWidth='1px'
      borderBottomColor='grey.200'
      justifyContent='flex-end'
      {...rest}>

      <HStack spacing='6'>

        <Flex>
          {username ?
            <Menu>
              <MenuButton
                py={2}
                _focus={{ boxShadow: 'none' }}>
                <HStack>
                  <Avatar size='sm' />
                  <Text fontSize='sm'>{username.toUpperCase()}</Text>
                </HStack>
              </MenuButton>
              <MenuList
                borderColor='gray.200'>
                <MenuItem>Profile</MenuItem>
                <MenuItem>Settings</MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => {
                  Auth.signOut();
                  history.push('/');
                }}>Sign out</MenuItem>
              </MenuList>
            </Menu>
            : <Link
              as={RouterLink}
              to='/login'
              style={{ textDecoration: 'none' }}
              _focus={{ boxShadow: 'none' }}>
              <Box
                bg='cyan.200'
                borderRadius={4}
                borderWidth={2}
                px={4}
                py={2}
                align='center'>
                <Text>
                  SignIn
                </Text>
              </Box>
            </Link>}
        </Flex>

      </HStack>
    </Flex>
  );
};

export default Navigator;