import { useSubscription } from '@apollo/client';
import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import queries from '../graphql-queries';

const SpeedWidget = ({ username, tripId, delay, ...rest }) => {

  const { data } = useSubscription(queries.TRIP_SUBSRIPTION, {
    variables: {
      username: username,
      tripId: tripId
    }
  });

  const [latestSpeed, setLatestSpeed] = useState(0);

  useEffect(() => {
    if (data?.onLocationDatum && data.onLocationDatum !== latestSpeed) {
      if (delay) {
        setTimeout(() => setLatestSpeed(data.onLocationDatum.speed), delay);
      } else {
        setLatestSpeed(data.onLocationDatum.speed);
      }
    }
  }, [data, delay, latestSpeed]);

  return <Box
    bg='none'
    color='black'
    fontWeight={700}
    fontSize={32}
    p={5}
    my={3}
    display='flex'
    alignItems='center'
    justifyContent='center'
    w={200}
    h={25}
    {...rest}
  >
    <Text>{Math.round(Number.EPSILON + 3.6 * latestSpeed)} km/h</Text>
  </Box >
};

export default SpeedWidget;